/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import amazonReportService from '@/http/requests/reports/amazonReportService.js'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null

    if (!filters) {
      // Return empty array when filters is null
      return {
        data: [],
        totalCount: 0,
      }
    }

    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : null
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    let sortOrder = ''
    if (sort) {
      pager.setSortOrder(sort)
      sortOrder = `&sort=${pager.toCreateSortOrder()}`
    }

    const pageableQuery = `${pager.toCreatePageable()}${sortOrder}`

    const response = await amazonReportService.getInventoryHistoricalValues(filters, pageableQuery)
    const data = response.data.content
    const totalCount = response.data.totalElements

    return { data, totalCount }
  },
})

const inventoryValueDataSource = new DataSource({
  store: store,
  requireTotalCount: true,
})

export {
  inventoryValueDataSource as InventoryValueDataSource,
}
