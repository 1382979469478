import {
  ref, // computed, watch, computed, reactive, onMounted,
} from '@vue/composition-api'
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'

export default function useDateSelection() {
  const date = ref('')
  const today = ref('')
  const currentDay = ref('')
  const dateRangesList = ref(getPredefinedDateRangesList())
  const selectedDateRange = ref(predefinedDateRangesEnum.CURRENTMONTH.value)
  const isCustomDateRange = ref(false)

  const firstDayOfMonth = new Date()
  const plusOneDay = new Date()
  firstDayOfMonth.setDate(1)
  firstDayOfMonth.setHours(0, 0, 0, 1)
  plusOneDay.setDate(plusOneDay.getDate() + 1)

  const beginDate = ref(firstDayOfMonth)
  const endDate = ref(plusOneDay)

  const setDateRanges = dateRangeSelection => {
    switch (dateRangeSelection.value) {
      case predefinedDateRangesEnum.TODAY.value:
        date.value = new Date()
        isCustomDateRange.value = false
        date.value.setHours(0, 0, 0, 1)
        beginDate.value = date.value
        break
      case predefinedDateRangesEnum.CURRENTWEEK.value:
        isCustomDateRange.value = false
        date.value = new Date()
        today.value = date.value.getDate()
        currentDay.value = date.value.getDay()
        date.value.setDate(today.value - (currentDay.value || 7))
        date.value.setHours(0, 0, 0, 1)
        beginDate.value = date.value
        break
      case predefinedDateRangesEnum.CURRENTMONTH.value:
        isCustomDateRange.value = false
        date.value = new Date()
        date.value.setFullYear(date.value.getFullYear())
        date.value.setMonth(date.value.getMonth())
        date.value.setDate(1)
        date.value.setHours(0, 0, 0, 1)
        beginDate.value = date.value
        break
      case predefinedDateRangesEnum.CURRENTQUARTER.value:
        isCustomDateRange.value = false
        date.value = new Date()
        date.value.setFullYear(date.value.getFullYear())
        date.value.setMonth(date.value.getMonth() - (date.value.getMonth() % 3))
        date.value.setDate(1)
        date.value.setHours(0, 0, 0, 1)
        beginDate.value = date.value
        break
      case predefinedDateRangesEnum.CURRENTYEAR.value:
        isCustomDateRange.value = false
        date.value = new Date()
        date.value.setFullYear(date.value.getFullYear())
        date.value.setMonth(0)
        date.value.setDate(1)
        date.value.setHours(0, 0, 0, 1)
        beginDate.value = date.value
        break
      case predefinedDateRangesEnum.LAST7DAYS.value:
        isCustomDateRange.value = false
        date.value = new Date()
        today.value = date.value.getDate()
        date.value.setDate(today.value - 7)
        beginDate.value = date.value
        break
      case predefinedDateRangesEnum.LAST30DAYS.value:
        isCustomDateRange.value = false
        date.value = new Date()
        today.value = date.value.getDate()
        date.value.setDate(today.value - 30)
        beginDate.value = date.value
        break
      case predefinedDateRangesEnum.LAST90DAYS.value:
        isCustomDateRange.value = false
        date.value = new Date()
        today.value = date.value.getDate()
        date.value.setDate(today.value - 90)
        beginDate.value = date.value
        break
      case predefinedDateRangesEnum.LAST365DAYS.value:
        isCustomDateRange.value = false
        date.value = new Date()
        today.value = date.value.getDate()
        date.value.setDate(today.value - 365)
        beginDate.value = date.value
        break
      default:
        isCustomDateRange.value = true
    }
  }

  return {
    setDateRanges,
    beginDate,
    endDate,
    selectedDateRange,
    isCustomDateRange,
    dateRangesList,
  }
}
