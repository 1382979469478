<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 px-0">
          <dx-data-grid
            id="historicalInventoryValuesGrid"
            ref="historicalInventoryValuesGridRef"
            :height="setHeight"
            :data-source="InventoryValueDataSource"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            :customize-columns="customizeColumns"
            @option-changed="onOptionChanged"
            @initialized="onInitialized"
            @content-ready="onContentReady"
            @cell-dbl-click="onCellDblClick"
            @toolbar-preparing="onToolbarPreparing($event)"
            @editor-preparing="onEditorPreparing"
          >
            <!--region    ::DataGird base components -->
            <dx-sorting mode="single" />
            <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="true" />
            <dx-search-panel
              :highlight-search-text="true"
              :visible="true"
              :width="200"
              placeholder="Search..."
            />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
            <!--endregion ::DataGird base components -->
            <dx-column data-field="companyName" caption="Company" :allow-sorting="false" />
            <dx-column data-field="storeName" caption="Store" :allow-sorting="false" />
            <dx-column data-field="accountNo" caption="Acc #" :allow-sorting="false" />
            <dx-column data-field="date" caption="Date" data-type="date" />
            <dx-column caption="Warehouse Inbound" alignment="center">
              <dx-column data-field="inboundQuantity" caption="Quantity" />
              <dx-column data-field="inboundTotalBuyValue" caption="Total Buy Value" :format="'$ #,##0.##'" />
              <dx-column data-field="inboundTotalSellValue" caption="Total Sell Value" :format="'$ #,##0.##'" />
            </dx-column>
            <dx-column caption="Warehouse Inventory" alignment="center">
              <dx-column data-field="inventoryQuantity" caption="Quantity" />
              <dx-column data-field="inventoryTotalBuyValue" caption="Total Buy Value" :format="'$ #,##0.##'" />
              <dx-column data-field="inventoryTotalSellValue" caption="Total Sell Value" :format="'$ #,##0.##'" />
            </dx-column>
            <dx-column caption="Amazon Inventory" alignment="center">
              <dx-column data-field="amzTotalQuantity" caption="Quantity" />
              <dx-column data-field="amzTotalBuyValue" caption="Total Buy Value" :format="'$ #,##0.##'" />
              <dx-column data-field="amzTotalSellValue" caption="Total Sell Value" :format="'$ #,##0.##'" />
            </dx-column>
            <template #toolbarFilters>
              <div class="d-flex px-half">
                <div v-if="hasPermission" class="mr-1">
                  <dx-util-text-box
                    v-model.trim="accountNo"
                    :show-clear-button="true"
                    mode="text"
                    placeholder="Account or Suite No"
                    @key-down="onFilterByAccountNo"
                    @value-changed="onFilterByAccountNo"
                  />
                </div>
                <div class="mr-1">
                  <dx-util-select-box
                    v-model="selectedStore"
                    :data-source="stores"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="searchByFilter"
                  />
                </div>
                <div class="mr-1">
                  <dx-util-select-box
                    v-model="selectedDateRange"
                    :data-source="dateRangesList"
                    :drop-down-options="{width: '180px'}"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="setDateRanges"
                  />
                </div>
                <div v-show="isCustomDateRange" class="mr-1">
                  <dx-util-date-box v-model="beginDate" :max="endDate" type="date" @value-changed="searchByFilter" />
                </div>
                <div v-show="isCustomDateRange" class="mr-1">
                  <dx-util-date-box v-model="endDate" :min="beginDate" type="date" @value-changed="searchByFilter" />
                </div>
                <div class="">
                  <dx-util-button id="chartButton" text="Show Chart" type="success" @click="showChart" />
                </div>
              </div>
            </template>
          </dx-data-grid>
        </div>
        <div class="col-12">
          <light-box
          :items="images"
          :index="index"
          :effect="'fade'"
          @close="index = null"
        />
        </div>
        <dx-util-popover :width="300" :visible="false"
          target="#chartButton" position="bottom"
          show-event="dxhoverstart"
          hide-event="dxhoverend"
        >
          Select a store to view the charts.
        </dx-util-popover>
        <div class="col-12">
          <historical-inventory-charts :component-id="chartsComponentId" :chart-data="chartData" @on-chart-closed="onChartClosed" />
        </div>
      </div>
    </div>
    <div class="col-md-12 py-1 position-absolute fixed-bottom">
      <div class="d-flex flex-row justify-content-start align-items-center">
        <p-icon name="bi-info-circle-fill" color="warning" size="32px" class="mr-1" />
        <span><strong>Beta Version: This is a beta version of this module. It is currently being tested and its usage is for free. Some features may be limited upon the full version release and will be subject to additional charges.</strong></span>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import predefinedDateRangesEnum from '@/enums/predefinedDateRanges.enum.js'
import itemActionEnum from '@/enums/item-action.enum.js'
import useDateSelection from '@/@core/composables/useDateSelection.js'
import InventorySearchFilter from '@/http/models/search-filters/InventorySearchFilter'
import { Notify } from '@/@robustshell/utils'
import { v4 as uuidv4 } from 'uuid'
import { InventoryValueDataSource } from './inventoryValueStore'

export default {
  components: {
    'light-box': () => import('vue-cool-lightbox'),
    'historical-inventory-charts': () => import('./HistoricalInventoryCharts.vue'),
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      InventorySearchFilter,
      InventoryValueDataSource,
      selectedStore: 0,
      accountNo: '',
      images: [],
      index: '',
      filters: {
        query: '',
      },
      chartsComponentId: '',
      chartData: [],
    }
  },
  setup() {
    const {
      setDateRanges, beginDate, endDate, selectedDateRange, isCustomDateRange, dateRangesList,
    } = useDateSelection()
    return {
      setDateRanges, beginDate, endDate, selectedDateRange, isCustomDateRange, dateRangesList,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      const dataGridIns = this.$refs.historicalInventoryValuesGridRef.instance
      return dataGridIns
    },
  },
  mounted() {
    this.getStores()
    this.loadData(true)
    this.pagerOptions.pageSize = 500
  },
  methods: {
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.loadData(false)
          },
        },
        location: 'after',
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        InventoryValueDataSource.pageIndex(0)
        InventoryValueDataSource.reload()
      }
    },
    onCellDblClick(e) {
      if (e.rowType === 'data' && e.column.dataField === 'smallImage') {
        if (e.eventType === 'dxdblclick') {
          if (e.data.largeImage) {
            this.images.length = 0
            this.index = 0
            this.images.push(e.data.largeImage)
          }
        }
      }
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.selectedStore = 0
        this.getStores()
        this.searchByFilter()
      }
    },
    loadData(initial) {
      this.selectedDateRange = predefinedDateRangesEnum.CURRENTMONTH.value
      const firstDayOfMonth = new Date()
      const plusOneDay = new Date()
      firstDayOfMonth.setDate(1)
      firstDayOfMonth.setHours(0, 0, 0, 1)
      plusOneDay.setDate(plusOneDay.getDate() + 1)
      InventorySearchFilter.setDefaultFilters()
      InventoryValueDataSource.sort([{ selector: 'date', desc: false }])
      if (initial) {
        InventorySearchFilter.beginDate = firstDayOfMonth
        InventorySearchFilter.endDate = plusOneDay
        InventorySearchFilter.itemAction = itemActionEnum.FBA.value
        const filters = InventorySearchFilter.getFilters()
        InventoryValueDataSource.searchValue(filters)
        InventoryValueDataSource.load()
      } else {
        if (this.hasPermission) this.accountNo = ''
        this.selectedStore = 0
        InventorySearchFilter.beginDate = this.beginDate
        InventorySearchFilter.endDate = this.endDate
        InventorySearchFilter.accountNo = this.accountNo
        InventorySearchFilter.itemAction = itemActionEnum.FBA.value
        this.dataGrid.clearFilter()
        const filters = InventorySearchFilter.getFilters()
        InventorySearchFilter.q = this.filters.query
        InventoryValueDataSource.searchValue(filters)
        InventoryValueDataSource.reload()
      }
    },
    searchByFilter() {
      InventorySearchFilter.accountNo = this.accountNo
      InventorySearchFilter.storeId = this.selectedStore
      InventorySearchFilter.beginDate = this.beginDate
      InventorySearchFilter.endDate = this.endDate
      InventorySearchFilter.itemAction = itemActionEnum.FBA.value
      InventorySearchFilter.q = this.filters.query

      const filters = InventorySearchFilter.getFilters()
      InventoryValueDataSource.searchValue(filters)
      InventoryValueDataSource.reload()
    },
    async showChart() {
      InventoryValueDataSource.sort([{ selector: 'date', desc: false }])

      if (this.selectedStore === 0) {
        Notify.warning('Please select a store to view the charts.')
        return
      }

      InventorySearchFilter.accountNo = this.accountNo
      InventorySearchFilter.storeId = this.selectedStore
      InventorySearchFilter.beginDate = this.beginDate
      InventorySearchFilter.endDate = this.endDate
      InventorySearchFilter.itemAction = itemActionEnum.FBA.value
      InventorySearchFilter.q = this.filters.query

      const filters = InventorySearchFilter.getFilters()
      InventoryValueDataSource.searchValue(filters)
      InventoryValueDataSource.searchValue(filters)
      await InventoryValueDataSource.reload().then(res => {
        this.chartData = res
      })
      if (this.chartData?.length === 0) {
        Notify.warning('No historical value data to show in charts')
        return
      }
      this.chartsComponentId = uuidv4()
    },
    onChartClosed() {
      this.chartData.splice(0, this.chartData.length)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
